import { render, staticRenderFns } from "./ArticlesSection.vue?vue&type=template&id=4470fec4&scoped=true&"
import script from "./ArticlesSection.vue?vue&type=script&lang=js&"
export * from "./ArticlesSection.vue?vue&type=script&lang=js&"
import style0 from "./ArticlesSection.vue?vue&type=style&index=0&id=4470fec4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4470fec4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VContainer,VIcon,VImg,VList,VListItem,VListItemContent,VListItemIcon})
